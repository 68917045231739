<!-- LanguageSelector.vue -->
<template>
  <select v-model="selectedLanguage" @change="changeLanguage">
    <option value="en">English</option>
    <option value="zh">中文</option>
    <!-- 其他語言選項... -->
  </select>
</template>

<script>
export default {
  data() {
    return {
      selectedLanguage: this.$i18n.locale,
    };
  },
  methods: {
    changeLanguage() {
      this.$i18n.locale = this.selectedLanguage;
    },
  },
};
</script>

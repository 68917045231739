<template>
  <div class="container mx-auto my-4" style="max-width: 600px;">
    <div v-if="loading" class="d-flex justify-content-center align-items-center vh-100">
      <b-modal v-model="loadingModal" id="loadingModal" :title="$t('loading')" hide-header hide-footer>
        <div class="text-center">
          <b-spinner variant="primary" :label="$t('loading')"></b-spinner>
          <p class="mt-2">{{ $t('loading') }}</p>
        </div>
      </b-modal>
    </div>
    <div v-else>
      <b-card class="mx-auto">
        <b-card-body>
          <img class="img-fluid" alt="DS Logo" src="@/assets/logo.png">
          <div class="mt-2" v-if="!userDisabled">
            <div class="d-flex justify-content-between align-items-center">
              <h2>{{ $t('mifTitle') }}</h2>
              <LanguageSelector/>
            </div>
            <h4>{{ $t(env === 'uat' ? 'envUAT' : env === 'prod' ? 'envProd' : 'envUnknown') }}</h4>
            <div class="d-flex justify-content-between align-items-center mb-3">
              <p v-if="user.uid != null" class="text-end">
                 <span class="highlighted-text">
                  {{ $t('projectCode') }} {{ user.uid }}
                  </span>
              </p>
              <div id="tooltip-target-1">
                <div class="d-flex align-items-center text-center">
                  <cloud-check-variant-outline-icon v-if="!autoSaving"/>
                  <span class="mx-1"></span>
                  <p v-if="autoSaved && !autoSaving">{{ $t('dataSaved') }}</p>
                </div>
                <div class="d-flex align-items-center text-center" v-if="autoSaving">
                  <sync-icon id="tooltip-target-2"/>
                  <span class="mx-1"></span>
                  <p>{{ $t('dataSaving') }}</p>
                </div>
              </div>
            </div>
            <b-tooltip target="tooltip-target-1" triggers="hover">
              {{ $t('autoSaveTooltip') }}
            </b-tooltip>

            <!-- 已經提交後需要提示的對話框 -->
            <b-card v-if="formData.isSubmitted" bg-variant="warning" :title="$t('formSubmitted')">
              <b-card-text>
                <span v-html="$t('formArchivedMessage')"></span>
              </b-card-text>
            </b-card>
            <!-- 共用表單字段 -->
            <b-form @submit.prevent="submitForm" class="mt-2">
              <b-form-group id="operatorName" :label="$t('operatorName')">
                <b-form-input v-model="formData.operatorName" required
                              :placeholder="$t('operatorNamePlaceholder')"
                              :readonly=formData.isSubmitted></b-form-input>
              </b-form-group>
              <b-form-group id="operatorWebsite" :label="$t('operatorWebsite')">
                <b-form-input v-model="formData.operatorWebsite" required
                              :placeholder="$t('operatorWebsitePlaceholder')"
                              :readonly=formData.isSubmitted></b-form-input>
              </b-form-group>
              <b-form-group id="currency" :label="$t('currency')">
                <b-form-input v-model="formData.currency" required
                              :placeholder="$t('currencyPlaceholder')"
                              :readonly=formData.isSubmitted></b-form-input>
              </b-form-group>
              <b-form-group id="defaultLanguage" :label="$t('defaultLanguage')">
                <b-form-input v-model="formData.defaultLanguage" required
                              :placeholder="$t('defaultLanguagePlaceholder')"
                              :readonly=formData.isSubmitted></b-form-input>
              </b-form-group>
              <!-- Whitelist Request -->
              <b-form-group id="uatApiServer" :label="$t('apiServerLabel')">
                <b-form-input v-model="formData.apiServer" required
                              :placeholder="$t('apiServerPlaceholder')"
                              :readonly=formData.isSubmitted></b-form-input>
              </b-form-group>
              <b-form-group id="uatBackOffice" :label="$t('backOfficeLabel')">
                <b-form-input v-model="formData.backOffice" required
                              :placeholder="$t('backOfficePlaceholder')"
                              :readonly=formData.isSubmitted></b-form-input>
              </b-form-group>
              <b-form-group id="uatGameServer" :label="$t('gameServerLabel')">
                <b-form-input v-model="formData.gameServer" required
                              :placeholder="$t('gameServerPlaceholder')"
                              :readonly=formData.isSubmitted></b-form-input>
              </b-form-group>
            </b-form>
          </div>
        </b-card-body>
      </b-card>
      <div class="d-flex justify-content-between align-items-center mt-2" v-if="!formData.isSubmitted">
        <b-button @click="submitForm" variant="warning" size="sm">{{ $t('submitButton') }}</b-button>
        <b-button @click="showClearConfirmation" variant="outline-light" size="sm">{{
            $t('clearFormButton')
          }}
        </b-button>
      </div>
    </div>
  </div>

  <!-- 確認對話框模態 -->
  <b-modal v-model="clearFormModal"
           :title="$t('clearFormModalLabel')"
           hide-footer
           hide-header-close>
    <p>{{ $t('clearFormConfirmationMessage') }}</p>
    <div class="mt-3 d-flex justify-content-end align-items-center mx-auto">
      <b-button variant="secondary" size="sm" @click="closeModal">{{ $t('cancelButton') }}</b-button>
      <span class="mx-1"></span>
      <b-button @click="clearForm" variant="danger" size="sm">{{ $t('clearContentButton') }}</b-button>
    </div>
  </b-modal>
</template>

<style>
/* 修改其他元素的字體顏色 */
.card-body,
.form-label,
.form-control {
  color: #202124;
}

.highlighted-text {
  border: 2px solid #ff6600; /* 边框颜色和宽度 */
  padding: 5px 10px; /* 上下填充和左右填充 */
  display: inline-block; /* 使边框适应文本的宽度 */
  border-radius: 5px; /* 可选的圆角 */
}

/* 修改特定元素的字體顏色 */
.modal-title,
.modal-body p {
  color: #202124;
}

p {
  color: #202124;
}

.d-flex.align-items-center p {
  margin-bottom: 0;
}

.modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}
</style>

<script>
import axios from "axios";
import {getAuth, signInWithCustomToken} from "firebase/auth";
import {getFirestore, getDoc, setDoc, doc} from "firebase/firestore";
import {initializeApp} from 'firebase/app'
import {useToast} from "vue-toastification";
import LanguageSelector from './LanguageSelector.vue';
import SyncIcon from 'vue-material-design-icons/Sync.vue';
import CloudCheckVariantOutlineIcon from 'vue-material-design-icons/CloudCheckVariantOutline.vue';

const firebaseConfig = {
  apiKey: 'AIzaSyB3pIm4IEw-jopZJMhXjv2agOYV0p3Uvok',
  authDomain: 'spock-lab.firebaseapp.com',
  projectId: 'spock-lab',
  storageBucket: 'spock-lab.appspot.com',
  messagingSenderId: '317722653882',
  appId: '1:317722653882:web:e19e6dae43e40107c47e83',
  measurementId: 'G-6D2PDK477Q'
}


// Initialize Firebase
const app = initializeApp(firebaseConfig)

// Initialize Realtime Database and get a reference to the service
const db = getFirestore(app)

class FormData {
  constructor(operatorName, operatorWebsite, currency, defaultLanguage, apiServer, backOffice, gameServer) {
    this.operatorName = operatorName;
    this.operatorWebsite = operatorWebsite;
    this.currency = currency;
    this.defaultLanguage = defaultLanguage;
    this.apiServer = apiServer;
    this.backOffice = backOffice;
    this.gameServer = gameServer;
    this.isSubmitted = false;
  }
}

export default {
  name: 'FormPage',
  components: {
    LanguageSelector,
    CloudCheckVariantOutlineIcon,
    SyncIcon
  },
  watch: {
    'formData': {
      handler: 'AutoSyncDebounced',
      deep: true // 深度监听对象的变化
    }
  },
  setup() {
    // Get toast interface
    const toast = useToast();
    return {
      toast
    };
  },
  data() {
    return {
      userDisabled: false,
      autoSaving: false, // Save狀態
      autoSaved: false, // Save狀態
      loading: true, // 加載狀態
      loadingFailed: false, // 加載失敗狀態
      formData: FormData,
      initialFormData: FormData,
      documents: [],
      user: {},// 添加user屬性來存儲使用者資訊
      idTokenResult: {}, // 用於存儲 ID 令牌的詳細資訊
      messageHistory: [], // 存儲消息歷史記錄的數組
      docRef: {},
      env: "unknown",
      clearFormModal: false, // 控制模态框的显示和隐藏
      loadingModal: true, // 控制模态框的显示和隐藏
      debouncer: null // 用于存储定时器的变量
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      let token = this.$route.query.token
      let authURL = process.env.VUE_APP_API_URL + "/v1/auth/"
      axios.get(authURL + token).then((response) => {
        // 處理成功
        const auth = getAuth();
        signInWithCustomToken(auth, response.data.token)
            .then((userCredential) => {
              // Signed in
              // 將用戶資訊存儲到組件資料中
              this.user = userCredential.user;
              console.log(this.user.uid)

              // 調用 getIdTokenResult 函數以獲取 ID 令牌詳細資訊
              userCredential.user.getIdTokenResult().then((idTokenResult) => {
                this.env = idTokenResult.claims.env
                console.log("env: ", this.env)
                this.loadData(); // 調用加載數據的方法
              }).catch((error) => {
                console.error("獲取 ID 令牌詳細資訊時發生錯誤：", error);
              });
            }).catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode, errorMessage)
          if (errorCode === "auth/user-disabled") {
            this.userDisabled = true
          }

          setTimeout(() => {
            this.loading = false; // 初始化完成後停止加載
            this.loadingFailed = true; // 設置加載失敗狀態
          }, 2000); // 延遲2秒
        });
      }).catch((error) => {
        console.log(error);
        setTimeout(() => {
          this.loading = false; // 初始化完成後停止加載
          this.loadingFailed = true; // 設置加載失敗狀態
        }, 2000); // 延遲2秒
      }).finally(() => {
      });
    },
    async loadData() {
      if (!this.user) {
        this.toast.error(this.$t('userNotLoggedIn'));
        return;
      }

      try {
        const docSnap = await getDoc(doc(db, 'mif', this.user.uid, "env", this.env));
        if (docSnap.exists()) {
          // 文檔存在，將數據填充到表單
          const data = docSnap.data();
          this.initialFormData = {
            operatorName: data.mif.operatorName,
            operatorWebsite: data.mif.operatorWebsite,
            currency: data.mif.currency,
            defaultLanguage: data.mif.defaultLanguage,
            apiServer: data.mif.apiServer,
            backOffice: data.mif.backOffice,
            gameServer: data.mif.gameServer,
            isSubmitted: data.mif.isSubmitted,
          };
          // 将加载的数据也赋值给formData
          this.formData = {...this.initialFormData};
        } else {
          this.initialFormData = {};
          this.formData = {};
          console.log('文檔不存在');
        }
        setTimeout(() => {
          this.loading = false; // 初始化完成後停止加載
        }, 2000); // 延遲2秒
      } catch (error) {
        this.toast.error(this.$t('dataLoadError', {error: error}));
      }
    },
    async submitForm() {
      this.formData.isSubmitted = true;
      await this.SaveForm();
    },
    AutoSyncDebounced() {
      if (this.loading || this.formData.isSubmitted === true
      ) {
        return;
      }
      this.autoSaving = true;
      // 清除之前的定时器
      clearTimeout(this.debouncer);

      // 设置一个新的定时器，在用户停止输入后3秒触发存档操作
      this.debouncer = setTimeout(() => {
        // 检查 formData 是否与 initialFormData 相等，如果相等则不保存
        if (JSON.stringify(this.formData) !== JSON.stringify(this.initialFormData)) {
          console.log('SaveForm');
          this.formData.isSubmitted = false;
          this.SaveForm();
        }
      }, 3000); // 3秒延迟
    },

    async SaveForm() {
      // 確保用戶已登錄
      if (!this.user) {
        this.toast.error(this.$t('userNotLoggedIn'));
        return;
      }

      setDoc(doc(db, 'mif', this.user.uid, "env", this.env), {
        mif: {...this.formData},
        date: `${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`,
      }).then(() => {
        // 更新initialFormData以匹配formData
        this.initialFormData = {...this.formData};
        this.autoSaved = true;
        this.autoSaving = false;
      }).catch((error) => {
        // 將失敗消息添加到歷史記錄
        this.toast.error(this.$t('dataAddError', {error: error}));
      });
    },
    showClearConfirmation() {
      // 顯示清除表單確認模態
      this.clearFormModal = true;
    },
    closeModal() {
      // 关闭模态框的逻辑
      this.clearFormModal = false; // 请根据你的代码逻辑进行修改
    },
    clearForm() {
      // 關閉清除表單確認模態
      this.clearFormModal = false;
      this.formData = new FormData('', '', '', '', '', '', '');
    },
  }
}
</script>

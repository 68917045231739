import {createApp} from 'vue'
import App from './App.vue'
import FormPage from "@/components/FormPage.vue";
import 'bootstrap/dist/css/bootstrap.min.css'
import "bootstrap"

// 設置瀏覽器標題
document.title = 'DRAGOON SOFT';

const app = createApp(App)

import {createRouter, createWebHistory} from 'vue-router'

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/form',
            name: 'FormPage',
            component: FormPage,
            meta: { title: 'Merchant Integration Form' } // 首頁的標題
        },
    ],
})
router.beforeEach((to, from, next) => {
    // 设置页面标题
    document.title = to.meta.title || 'DRAGOON SOFT';
    next();
});

app.use(router)

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const options = {
    // 设置默认的消息通知位置为 "bottom-right"
    position: "top-right",

    // 设置默认的消息通知显示时间为 1700 毫秒（1.7秒）
    timeout: 1700,
};
app.use(Toast, options);

import {createI18n} from 'vue-i18n'

const i18n = createI18n({
    legacy: false, // 使用 Composition API
    locale: 'en', // 默認語言
    fallbackLocale: 'en', // 如果找不到翻譯，使用的語言
    messages: {
        en: require('./locales/en.json'), // 引入英文翻譯文件
        zh: require('./locales/zh.json')  // 引入中文翻譯文件
    }
})
app.use(i18n)

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import { BootstrapVueNext } from 'bootstrap-vue-next'
app.use(BootstrapVueNext)

app.mount('#app')
